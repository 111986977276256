import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AlertComponent } from '../components/alert/alert.component';

@Injectable({ providedIn: 'root' })
export class AlertService {
  private isDialogOpened = false;
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly snackBar: MatSnackBar = inject(MatSnackBar);

  public showDialog(
    mensaje: string,
    aceptar?: (inputValue?: string) => void,
    cancelar?: () => void,
    titulo = 'Información',
    icono = 'info',
    input = false,
    id?: string
  ) {
    if (this.isDialogOpened == false) {
      this.openDialog(mensaje, aceptar, cancelar, titulo, icono, input, id);
    }
  }

  public openSnackBar(message: string, duration?: number, action?: string, config?: MatSnackBarConfig): void {
    const snackbarConfig: MatSnackBarConfig = {
      duration: duration,
      ...config,
    };

    this.snackBar.open(message, action, snackbarConfig);
  }

  public manageError(error: HttpErrorResponse, id?: string) {
    if (!this.isDialogOpened) {
      let mensaje = undefined;
      switch (error.status) {
        case 400:
          mensaje = error.error.message ?? 'Error del servidor';
          break;
        case 401:
          mensaje = 'Nombre de usuario o contraseña incorrectos';
          break;
        case 404:
          mensaje = 'No se pudo encontrar el recurso solicitado';
          break;
        case 403:
          mensaje = 'No posee los permisos necesarios para ingresar';
          break;
        case 422:
          mensaje = this.parseErrors(error.error);
          break;
        case 500:
          mensaje = 'Error del servidor';
          break;
        default:
          mensaje = 'Error del servidor';
          break;
      }

      this.openDialog(mensaje, undefined, undefined, 'Error', 'warning', false, id);
    }
  }

  public parseErrors(serverErrors: { errors: { [key: string]: string }; status: number }): string {
    let message = '';
    for (const key in serverErrors.errors) {
      message += serverErrors.errors[key] + '. <br>';
    }
    return message;
  }

  private openDialog(
    message: string,
    accept?: (inputValue?: string) => void,
    cancel?: () => void,
    title?: string,
    icon?: string,
    input = false,
    id?: string
  ) {
    this.isDialogOpened = true;
    const dialogRef = this.dialog.open(AlertComponent, {
      closeOnNavigation: true,
      width: '20vw',
      disableClose: true,
      data: {
        icon: icon,
        title: title,
        message: message,
        accept: accept,
        cancel: cancel,
        input: input,
      },
      id,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.isDialogOpened = false;
      if (result !== undefined && accept) {
        accept(result);
      }
    });
  }

  public closeDialogById(id: string): void {
    this.isDialogOpened = false;
    this.dialog.getDialogById(id)?.close();
  }
}
